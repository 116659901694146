::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c4c4c4;
}
* {
  scrollbar-color: light;
  scrollbar-width: thin;
  scrollbar-color: #c4c4c4 transparent;
}
.scrollbar-on-hover {
  scrollbar-color: transparent transparent;
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:hover {
    scrollbar-color: #c4c4c4 transparent;
    &::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
    }
  }
}

// chrome autofil
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}
