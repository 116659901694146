@use '@angular/material' as mat;
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
// @import "../node_modules/intl-tel-input/build/css/intlTelInput.css";
@import "@angular/material/theming";

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
$brandClr: (
  50: #e1f0fb,
  100: #b5d9f4,
  200: #84c0ed,
  300: #53a6e5,
  400: #2e93e0,
  500: #0980da,
  600: #0878d6,
  700: #066dd0,
  800: #0563cb,
  900: #0082c8,
  A100: #ebf2ff,
  A200: #b8d0ff,
  A400: #85afff,
  A700: #0082c8,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
// $my-theme: mat.define-light-theme((
//  color: (
//   primary: $my-primary,
//   accent: $my-accent,
//   warn: $my-warn,
// ),
// typography: mat.define-typography-config(),
// density: 0,
// ));

$form-brand-primary: mat.define-palette($brandClr);
$form-brand-accent: mat.define-palette($brandClr, 500, A100, A400);
$form-brand-theme: mat.define-light-theme((color: (primary: $form-brand-primary,
        accent: $form-brand-accent ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($form-brand-theme);

// @include mat.all-component-themes($form-brand-theme); // for all components theme
@include mat.typography-hierarchy($form-brand-theme);

// Include the theme mixins for other components you use here.
// @include mat.button-theme($form-brand-theme);
@include mat.checkbox-theme($form-brand-theme);
@include mat.radio-theme($form-brand-theme);
@include mat.select-theme($form-brand-theme);
@include mat.slide-toggle-theme($form-brand-theme);

@import "assets/style/base";
@import "assets/style/style";
@import "assets/style/responsive";
