@import "color_theme";
@import "ngx-toastr";

body {
  padding: 0;
  margin: 0;
}

.main_container {

  // @media only screen and (max-width: 1125px){
  //    width: 100vw;
  // }
  * {
    font-family: $fontDefault;
  }

  .text_18 {
    font-size: 18px;
    line-height: 22px;
    margin: 0;
  }

  .text_16 {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
  }

  .text_15 {
    font-size: 15px;
    color: #212121;
    margin: 0;
  }

  .text_14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #212121;
    margin: 0;
  }

  .text_13 {
    font-size: 13px;
    line-height: 18px;
    margin: 0;
  }

  .text_20 {
    font-size: 20px;
    line-height: 20px;
  }

  .text_26 {
    font-weight: 600;
    font-size: 26px;
    line-height: 36px;
    color: $color_004;
    margin: 0;
  }

  .btn_001 {
    border: none;
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    padding: 16px 24px;
    margin-top: 20px;
    outline: none;
    background: $color_002;
    cursor: pointer;
  }

  .btn_002 {
    border: none;
    color: $color_002;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    outline: none;
    display: flex;
    align-items: center;

    img {
      max-width: 20px;
      margin-right: 10px;
    }
  }

  .edit_btn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 22px;
    right: 22px;
    padding: 10px;
    margin: 0;
    border: none;
    background: transparent;
    outline: none;
    box-shadow: none;
    cursor: pointer;

    svg {
      display: block;
      margin-right: 10px;
    }

    .edit_text {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #212121;
    }
  }

  .header_wrapper {
    width: 100%;
    padding: 18px 50px;
    border-bottom: 1px solid $sectionSeparateColor;
    box-sizing: border-box;
  }

  .nav_wrapper {
    width: 100%;
    padding: 18px 50px;
    border-bottom: 1px solid $sectionSeparateColor;
    display: flex;
    box-sizing: border-box;

    .text_18 {
      color: $color_001;
      font-weight: 600;
      margin-right: auto;
    }

    .help_wrp {
      margin-left: auto;
      display: flex;

      .nd_hlp_icon {
        padding-right: 8px;
        width: 26px;
      }
    }

    .text_13 {
      color: $color_002;
      font-weight: 500;
    }
  }

  .main_wrapper {
    padding: 40px;
    background: $mainBackground;
    min-height: 100vh;

    @media only screen and (max-width: 991px) {
      padding: 0;
      max-width: 100%;
    }

    .form_wrapper {
      max-width: 820px;
      margin: auto;
      font-family: $fontDefault !important;

      @media only screen and (max-width: 1125px) {
        max-width: 100%;
        // margin: 0px;
      }

      .form_expansion {
        font-family: $fontDefault !important;
        width: 100%;

        .expansion_panel {
          position: relative;
          // @media only screen and (max-width: 1125px){
          //   position: static;
          // }
          border-radius: 10px;
          background-color: rgb(255, 255, 255);
          margin-bottom: 16px;
          padding: 32px;
          box-sizing: border-box;

          @media only screen and (max-width: 767px) {
            border-radius: 0;
            padding: 16px;
            margin-bottom: 12px;
          }

          .expansion_header {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            // @media only screen and (max-width: 1125px){
            //   position: static;
            // }
            overflow: hidden;

            .active_header {
              margin-bottom: 40px;
            }
          }

          .expansion_body {
            background: #fff;

            &.header_mt_45 {
              overflow: visible;
            }

            // app-personal-information {
            //   display: block;
            //   .sm_detail_header {
            //     margin-top: 24px;
            //   }
            //   .fld_step_002_wrp {
            //     margin-top: 24px;
            //   }
            // }
          }

          .text_26 {
            opacity: 0.4;
            transition: 0.3s;
          }

          .text_20 {
            color: $color_004;
            font-weight: 600;
            margin: 0 0 24px 0;

            @media only screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 140%;
              margin-bottom: 16px;
              padding-top: 24px;
            }
          }

          .expansion_icon {
            width: 25px;
            height: 25px;
            margin-right: 15px;
            transition-duration: 0.3s;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            // &.prsnl_info {
            //   background-image: url(./assets/images/personal_information_uat.svg);
            // }
            // &.agrmt_acpt {
            //   background-image: url(./assets/images/agreement_accept.svg);
            //   width: 23px;
            //   height: 23px;
            // }
            // &.acdmc_intrs {
            //   background-image: url(./assets/images/academic_interst.svg);
            //   width: 22px;
            //   height: 22px;
            // }
          }

          .expansion_content {
            display: block;

            .text_16 {
              color: $color_003;
            }

            .detail_text_area {
              margin-top: 40px;

              // + .detail_text_area {
              //   margin-top: 40px;
              // }
              @media only screen and (max-width: 767px) {
                margin-top: 0;
              }
            }
          }

          .active_header {
            .text_26 {
              opacity: 1;
            }

            // .expansion_icon {
            //   &.prsnl_info {
            //     background-image: url(./assets/images/personal_information.svg);
            //   }
            //   &.acdmc_intrs {
            //     background-image: url(./assets/images/academic_interst_uat.svg);
            //   }
            //   &.agrmt_acpt {
            //     background-image: url(./assets/images/agreement_accept_uat.svg);
            //   }
            // }
          }

          .sm_detail_header {
            // width: 100%;
            // border-radius: 8px;
            // // background: $color_002;
            // // padding: 10px 20px;
            // color: #fff;
            // display: flex;
            // margin-top: 10px;
            cursor: context-menu;

            .detail_btn_wrapper {
              width: 55px;
              margin-left: auto;
            }

            .detail_area {
              .text_14 {
                max-width: 342px;
                margin: 12px 0 0 0;
              }
            }

            .sm_tit_hdr {
              margin-bottom: 5px;
            }
          }

          .sub_details {
            position: relative;
            // @media only screen and (max-width: 1125px){
            //   position: static;
            // }
            z-index: 999;
            top: 0px;
            left: 0;
            width: 100%;
            transition-delay: 0.3s;
            transition-duration: 0.1s;

            &.ng-star-inserted {
              .sm_detail_header {
                width: 100%;
                transition-duration: 0.3s;
              }
            }

            &.ng-animating.ng-star-inserted {
              .sm_detail_header {
                width: 293px;
              }
            }

            &.programTypeSm {
              margin-top: 30px;
            }
          }
        }
      }
    }

  .fld_wrapper {

      .combined-fields {
        .mat-mdc-form-field {
          .mat-mdc-text-field-wrapper {
            border-radius: 6px 0 0 6px;
          }

          & + .mat-mdc-form-field {

            .mat-mdc-text-field-wrapper {
              border-left: 0;
              border-radius: 0 6px 6px 0;
            }
          }
        }
      }

      &.fld_mt_32Imp {
        margin-top: 32px;
        // @media only screen and (max-width: 860px) {
        //   margin-top: 0px;
        // }
      }

      &.fld_mt_20Imp {
        margin-top: 20px;
        // @media only screen and (max-width: 860px) {
        //   margin-top: 0px;
        // }
      }

      &.fld_mt_18Imp {
        margin-top: 38px;

        @media only screen and (max-width: 767px) {
          margin-top: 24px;
        }
      }

      // &.fld_mt_15Imp {
      //   margin-top: 15px;
      //   @media only screen and (max-width: 860px) {
      //     margin-top: 0px;
      //   }
      // }
      &.fld_mt_0Imp {
        margin-top: 0px;

        @media only screen and (max-width: 767px) {
          margin-top: 16px;
        }
      }

      .mat-mdc-radio-button {
        margin-bottom: 0;
      }

      .fld_row {
        display: flex;
        margin-left: -15px;
        flex-wrap: wrap;
        margin-right: -15px;

        &.mt_20 {
          margin-top: 20px;
        }

        .fld_col_100 {
          flex: 1 1 100%;
          max-width: 100%;
          padding-left: 15px;
          padding-right: 15px;
          box-sizing: border-box;
        }

        .fld_col_50 {
          flex: 1 1 50%;
          max-width: 50%;
          padding-left: 15px;
          padding-right: 15px;
          box-sizing: border-box;
        }

        .fld_col_25 {
          flex: 1 1 25%;
          max-width: 25%;
          padding-left: 15px;
          padding-right: 15px;
          box-sizing: border-box;
        }
      }

      .cust_fld {
        display: block;
        padding-bottom: 20px;

        .mat-mdc-text-field-wrapper {
          background: #ffffff;
          padding: 6px 13px;
          border: 1px solid var(--ec-form-field-border-color);
          border-radius: 6px;

          .mat-mdc-input-element {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: var(--ec-form-field-value-color);

            &:-webkit-autofill {
              background-color: #fff !important;
            }
          }
        }

        .mat-mdc-floating-label {
          top: 18px;
          max-width: 100%;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          color: var(--ec-form-field-label-color);
        }

        .mdc-text-field--filled .mdc-floating-label--float-above {
          top: 20px;
        }

        .mat-mdc-form-field-error-wrapper {
          padding: 0;
        }

        .mat-mdc-form-field-flex {

          .mat-mdc-form-field-infix {
            min-height: auto;
            padding-bottom: 0;
            padding-top: 14px;

            .mat-mdc-form-field-wrapper {
              left: 16px;
              padding-top: 0;
              top: 0;
              display: flex;
              align-items: center;
              width: calc(100% - 20px);

              .mat-form-field-label {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: var(--ec-form-field-label-color);
                margin: auto 0;
              }
            }
          }
        }

        &.dob-field {
          .mat-mdc-form-field-flex {
            background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.3242 1.01562H10.1562V0.40625C10.1562 0.181898 9.97438 0 9.75 0C9.52562 0 9.34375 0.181898 9.34375 0.40625V1.01562H3.65625V0.40625C3.65625 0.181898 3.47438 0 3.25 0C3.02562 0 2.84375 0.181898 2.84375 0.40625V1.01562H1.67578C0.75174 1.01562 0 1.76737 0 2.69141V11.3242C0 12.2483 0.75174 13 1.67578 13H11.3242C12.2483 13 13 12.2483 13 11.3242V2.69141C13 1.76737 12.2483 1.01562 11.3242 1.01562ZM12.1875 11.3242C12.1875 11.801 11.801 12.1875 11.3242 12.1875H1.67578C1.199 12.1875 0.8125 11.801 0.8125 11.3242V4.5957C0.8125 4.5256 0.86935 4.46875 0.939453 4.46875H12.0605C12.1307 4.46875 12.1875 4.5256 12.1875 4.5957V11.3242Z' fill='%23212121'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: calc(100% - 16px) center;
          }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mdc-line-ripple {
          display: none;
        }

        .mat-mdc-select-arrow-wrapper {
          height: auto;
          // transform: translateY(0);

          .mat-mdc-select-arrow {
            // border: 2px solid $color_004;
            // background-color: transparent;
            // width: 9px;
            // height: 9px;
            // border-top: 2px solid transparent;
            // border-left: 2px solid transparent;
            // transform: rotate(45deg) translateY(-4px);
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.65989 5.13025C4.49859 5.13025 4.33731 5.06867 4.21434 4.94575L0.344539 1.07591C0.0983697 0.829744 0.0983697 0.430623 0.344539 0.184553C0.590609 -0.0615175 0.989651 -0.0615175 1.23584 0.184553L4.65989 3.6088L8.08395 0.184672C8.33012 -0.0613979 8.72912 -0.0613979 8.97517 0.184672C9.22146 0.430742 9.22146 0.829864 8.97517 1.07603L5.10544 4.94587C4.9824 5.06881 4.82113 5.13025 4.65989 5.13025Z' fill='%234B4B4B'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            height: 6px;

            svg {
              display: none;
            }
          }
        }

        .mat-mdc-select-value {
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #212121;
        }

        // /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        // /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        // /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        // &.mat-form-field-should-float {
        //   .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
        //     padding-top: 21px;
        //     padding-bottom: 9px;

        //     .mat-form-field-label {
        //       transform: translateY(-8px) scale(0.8) perspective(100px) translateZ(0px);
        //     }
        //   }

        //   /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        //   .mat-mdc-select-arrow-wrapper {
        //     transform: translateY(-8px);
        //   }
        // }

        // &.mat-form-field-invalid {
        //   .mat-mdc-form-field-flex {
        //     .mat-mdc-form-field-infix .mat-mdc-text-field-wrapper .mat-form-field-label {
        //       color: #f44336;
        //     }

        //     border: 1px solid #f44336;
        //   }

        //   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        //   .mat-form-field-subscript-wrapper {
        //     top: calc(100% - 28px);
        //     font-size: 13px;
        //   }
        // }

        &.select_cntry {

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-form-field-suffix {
            display: none;
          }

          .mat-mdc-form-field-flex {
            flex-direction: row-reverse;
          }

          .mat-mdc-text-field-wrapper {
            overflow: visible;
          }

          input.mat-mdc-input-element {
            transform: translate(14px, -4px);
          }

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-form-field-should-float {
            .mat-mdc-form-field-infix {
              padding-bottom: 10px;
              padding-top: 30px;
            }

            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-suffix {
              transform: translate(20px, -3px);
              display: block;
            }

            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-label {
              transform: translate(-29px, -11px) scale(0.8) perspective(100px) translateZ(0.001px);
              font-weight: 500;
              opacity: 0.7;
            }
          }
        }

        &.search-field {
          .mat-form-field-flex {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7.84442' cy='7.84439' r='5.99237' stroke='%23212121' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.0122 12.3234L14.3616 14.6667' stroke='%23212121' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: calc(100% - 16px) center;
            .mat-mdc-select-arrow-wrapper {
                .mat-mdc-select-arrow {
                border: none;
                background-color: transparent;
              }
            }
          }
        }
      }

      .mat-form-field-invalid {
        --mdc-theme-error: var(--ec-form-error-color);
        --ec-form-field-label-color: var(--mdc-theme-error);

        .mat-mdc-text-field-wrapper {
          border-color: var(--ec-form-error-color);
        }

        .mat-mdc-form-field {
          .mat-mdc-input-element {
            color: var(--ec-form-error-color);
            -webkit-text-fill-color: var(--ec-form-error-color);
          }
        }
      }

      .radio_box_row {
        display: flex;
        padding-bottom: 20px;

        @media only screen and (max-width: 767px) {
          padding-bottom: 0;
        }
      }

      .phone_fld_wrp {
        display: block;
        height: 100%;
        position: relative;

        // margin-bottom: 20px;
        .mat-mdc-form-field-error {
          position: absolute;
          top: 0px;
          transition-duration: 0.3s;
          font-size: 13px;
        }

        .phone_fld {
          display: block;
          height: 100%;

          &.ng-invalid.ng-touched {
            .form-control {
              border-color: #f44336 !important;
            }

            ~ .mat-mdc-form-field-error {
              top: auto;
              bottom: 4px;
            }
          }
        }

        .intl-tel-input {
          display: block;
          height: 48px;
          z-index: 99;
        }

        .form-control {
          display: block;
          height: 48px;
          font-weight: 400;
          font-size: 14px;
          outline: none;
          border-radius: 8px;
          padding-left: 90px;

          &:focus {
            outline: none;
            box-shadow: none;
          }
        }

        .intl-tel-input.separate-dial-code {
          .selected-flag {
            background: #fff;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding-left: 16px;
            position: relative;

            // &::after {
            //   content: " ";
            //   width: 1.5px;
            //   height: 27px;
            //   background: #ced4da;
            //   position: absolute;
            //   right: 1px;
            //   border: none;
            // }
            // .selected-dial-code {
            //   display: none;
            // }
            .iti-arrow {
              border: 2px solid $color_004;
              background-color: white;
              width: 9px;
              height: 9px;
              border-top: 2px solid transparent;
              border-left: 2px solid transparent;
              transform: rotate(45deg) translateY(-4px);
              margin-left: 10px;
            }
          }

          // .selected-dial-code {
          //   display: none;
          // }
          .iti-flag {
            display: none;
          }

          .country-dropdown {
            .iti-flag {
              display: block;
            }
          }
        }

        .search-container {
          input {
            padding: 10px 20px !important;
            box-sizing: border-box;
            box-shadow: none;
          }
        }

        .intl-tel-input .country-list {
          overflow: hidden;
          overflow-y: auto;
          max-width: 458px;

          .country {
            padding: 13px 20px;
          }
        }
      }

      .title_001 {
        font-weight: 600;
        color: $color_004;
        padding: 13px 0 14px 0;
        margin: 0;

        &.is-invalid {
          color: #f44336;
        }

        &.title_001_lg {
          font-weight: 500;
          padding: 0 0 18px 0;
          font-size: 15px;
          line-height: 23px;

          @media only screen and (max-width: 767px) {
            padding: 0 0 16px 0;
          }
        }
      }

      .expansion_btn_wrapper {
        margin-top: 20px;
        border-top: 1px solid $sectionSeparateColor;
        padding-top: 40px;
      }
    }

    .fld_wrapper.fld_mt_20Imp:first-child {
      margin-top: 0;

      @media only screen and (max-width: 767px) {
        margin-top: 20px;
      }
    }

    .academic_stp_001 {
      // margin-right: -15px;
      // margin-left: -15px;
      transition-delay: 0.31s;
      transition-duration: 0.3s;
      background: #fff;
      position: relative;
      margin-top: 32px;

      .mdc-form-field {
        text-align: center;
      }

      @media only screen and (max-width: 767px) {
        // position: static;
        margin-top: 0;
      }

      .academic_btn_001 {
        width: 236px;

        background: #fff;
        border: 1px solid rgba(145, 143, 150, 0.25);
        border-radius: 6px;
        // margin: 6px 12px;
        margin-bottom: 0;
        outline: none;
        transition-duration: 0.5s;
        // padding: 0px;
        margin-right: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:last-child {
          margin-right: 0;
        }

        @media only screen and (max-width: 767px) {
          width: calc(100% / 2 - 12px);
          margin-right: 12px;
        }

        @media only screen and (max-width: 320px) {
          width: 100%;
          margin-right: 0;
          margin-top: 10px;
        }

        .text_002_academic_btn {
          margin: 0;
        }

        .deg_type {
          .text_academic_btn {
            margin: auto !important;
          }
        }

        .text_academic_btn {
          font-weight: 500;
          font-size: 15px;
          color: #7e8599;
          line-height: 1.2;
          margin-top: 40px;
          margin-bottom: 10px;
          transition-duration: 0.5s;

          @media only screen and (max-width: 767px) {
            margin-top: 12px;
            margin-bottom: 4px;
            font-size: 13px;
          }
        }

        .text_002_academic_btn {
          font-weight: 400;
          font-size: 13px;
          line-height: 19px;
          color: #7e8599;
          transition-duration: 0.5s;

          @media only screen and (max-width: 767px) {
            font-size: 13px;
          }
        }

        &:hover {
          border-color: $color_002;
          background-color: $color_009;

          .text_academic_btn {
            color: $color_001;
          }
        }

        .mdc-label {
          position: static;
          // min-height: 204px;
          // @media only screen and (max-width: 725px) {
          //   min-height: 150px;
          // }
          padding: 24px 20px;
          margin: 0;
          z-index: 10;
          cursor: pointer;

          @media only screen and (max-width: 767px) {
            // min-height: 140px;
            padding: 20px;
          }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        &.mat-mdc-radio-checked {
          background: #f2f9fc;
          border-color: #0082c8;
        }

        .deg_type_img {
          margin: 0;
          padding: 0;

          @media only screen and (max-width: 767px) {
            max-height: 41px;
          }
        }

        .text_academic_btn,
        .deg_type_img,
        .deg_type_text {
          transition-delay: 0.3s;
          transition-duration: 0.2s;
          // @media only screen and (max-width: 1125px){
          //   position: static;
          // }
        }

        &.mat-mdc-radio-checked {
          position: relative;
          // @media only screen and (max-width: 1125px){
          //   position: static;
          // }
          overflow: hidden;
          height: auto !important;

          &::after {
            content: " ";
            opacity: 1;
            background: $color_002;
          }

          // .text_academic_btn {
          //   color: #fff;
          // }
          .text_academic_btn,
          .text_002_academic_btn {
            color: $color_002;
          }

          // .text_academic_btn {
          //   opacity: 0;
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          // }
          // .deg_type_img,
          // .deg_type_text {
          //   opacity: 0;
          // }
        }
      }

      .degreeLevelTop {
        .academic_btn_001 {
          z-index: auto;
        }
      }

      .academic_stp_btn {
        margin-top: 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        // margin-bottom: 12px;
        // position: static;
        @media only screen and (max-width: 767px) {
          justify-content: space-between;
        }
      }

      .academic_stp_002_btn {
        margin-top: 0px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        // @media only screen and (max-width: 1125px){
        //   flex-direction: column;

        // }
        // margin-bottom: 20px;
        // @media only screen and (max-width: 767px) {
        //   margin-bottom: 0;
        // }
        .academic_btn_001 {
          width: calc(33.3% - 24px);
          text-align: center;
          margin: 8px 12px 0 0;
          z-index: auto;

          @media only screen and (max-width: 767px) {
            width: 100%;
            margin: 0 0 12px 0;

            &:last-child {
              margin: 0;
            }
          }

          .mdc-label {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            min-height: auto;
            padding: 22px 10px;
            text-align: center;
            z-index: auto;

            // @media only screen and (max-width: 767px) {
            //   padding: 18px 15px;
            // }

            .mdc-form-field {
              position: relative;
              display: block;
              height: 100%;

              .radio_style_wrapper {
                display: flex;
                justify-content: center;

                @media only screen and (max-width: 767px) {
                  justify-content: flex-start;
                }
              }

              .text_002_academic_btn {
                word-wrap: break-word;
                white-space: pre-wrap;
                font-weight: 400;
                font-size: 13px;

                span {
                  font-weight: 500;
                }
              }

              .radio_info_btn {
                position: absolute;
                top: -3px;
                right: -3px;
                // background-image: url(./assets/images/info.svg);
                background-image: url("../images/info.svg");
                width: 18px;
                height: 18px;
                background-position: center;
                background-size: 18px;
                opacity: 0;
                transition-duration: 0.3s;

                .radio_info_details {
                  background: #fff;
                  border: 1px solid $color_010;
                  position: absolute;
                  width: 360px;
                  border-radius: 8px;
                  left: -180px;
                  top: 30px;
                  opacity: 0;
                  z-index: 9999;
                  transition-duration: 0.2s;
                  visibility: hidden;
                  padding: 20px;
                  box-shadow: 0px 0px 6.65px 0.35px rgba(0, 0, 0, 0.12);
                  word-wrap: break-word;
                  white-space: pre-wrap;

                  &:after {
                    width: 0px;
                    height: 0px;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid #ffffff;
                    content: " ";
                    position: absolute;
                    top: -10px;
                    left: 50%;
                    transform: translateX(-1px);
                  }

                  .tooltip_text {
                    font-size: 13px;
                    font-weight: 400;
                    margin-bottom: 5px;

                    &:last-child {
                      margin-bottom: 0;
                    }

                    span {
                      font-weight: 500;
                      margin-right: 6px;
                    }
                  }
                }

                &:hover {
                  .radio_info_details {
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }

              .text_academic_btn {
                margin: auto 10px;
              }
            }

            &:hover {
              .radio_info_btn {
                opacity: 1;
              }
            }
          }
        }
      }

      .mat-mdc-radio-button .mat-ripple-element {
        background: $color_002;
        opacity: 0.6;
      }

      .mdc-radio {
        display: none;
      }

      .mdc-form-field {
        cursor: pointer;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        word-break: break-word;
        white-space: pre-wrap;
        text-align: center;
      }

      &.degreeLevelTop {
        margin-top: 0;

        &.academicActive {
          z-index: 9999;

          .academic_btn_001 {
            display: none;
          }
        }
      }
    }

    .academic_stp_text_002 {
      margin-top: 32px;

      @media only screen and (max-width: 767px) {
        margin-top: 16px;
      }

      .mat-mdc-radio-button .mdc-radio {
        padding: 0 6px 0px 0px;
      }

      .academic_stp_001 {
        overflow: visible;
      }

      .academic_stp_text_002_sps {
        margin-bottom: 25px;
      }
    }

    .academic_stp_text_003 {
      .academic_stp_text_003_sps {
        margin-bottom: 32px;

        @media only screen and (max-width: 767px) {
          margin-bottom: 16px;
        }
      }
    }

    .academic_stp_002 {
      background: #f8fafb;
      box-shadow: 2px 3px 3px rgb(0 0 0 / 2%);
      border-radius: 6px;
      margin-bottom: 20px;
      padding-bottom: 24px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 12px;
      }

      .search_curs_wrapper {
        width: 100%;
        border-bottom: 1px solid #ecedf0;

        .searh_curs_fld {
          display: block;
          width: 100%;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #7e8599;
          padding: 20px 20px 20px 56px;
          border-radius: 8px;
          background-color: transparent;
          background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6.84442' cy='6.84439' r='5.99237' stroke='%23212121' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.0122 11.3234L13.3616 13.6667' stroke='%23212121' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          border: none;
          background-position: 25px center;
          outline: none;

          @media only screen and (max-width: 767px) {
            font-size: 13px;
            line-height: 19px;
            padding: 16px 16px 16px 40px;
            background-position: 18px center;
          }
        }
      }

      .curs_list_wrapper {
        .mat-mdc-radio-group {

          // padding-top: 24px;
          .program_panel_item {
            padding: 12px 24px;

            @media only screen and (max-width: 767px) {
              padding: 8px 15px;
            }

            .mdc-label {
              font-size: 14px;
              color: #212121;

            }
          }
        }

        .text_level {
          margin: 0;
          font-size: 12px;
          line-height: 12px;
          // width: 100vw;
        }

        .material-icons {
          font-family: "Material Icons" !important;
        }

        .mat-mdc-icon-button {
          outline: none;
          margin-left: 10px;
        }

        .mat-tree-node {
          min-height: auto !important;
          background-color: $color_010;
        }

        .program_list_item {
          padding: 0;
          box-sizing: border-box;
          border: 1px solid transparent;
          border-top: 1px solid $color_012;
          transition-duration: 0.3s;
          padding-right: 20px;

          &.item_active {
            border: 1px solid $color_013;
            border-radius: 6px;
            background: $color_009;
          }

          .mdc-label {
            margin: 0;
            padding: 14px 0;
            box-sizing: border-box;
          }

          .mat-mdc-radio-button {
            width: auto;
          }

          &:hover {
            background: $color_009;
          }

          .prg_list_bdg {
            padding: 6px 8px;
            font-size: 13px;
            line-height: 13px;
            margin-left: auto;
            border-radius: 5px;
            font-weight: 500;

            &.badge_color_001 {
              color: $badge_color_001;
              border: 1px solid $badge_color_001;
              background: $badge_bg_001;
            }

            &.badge_color_002 {
              color: $badge_color_002;
              border: 1px solid $badge_color_002;
              background: $badge_bg_002;
            }

            &.badge_color_003 {
              color: $badge_color_003;
              border: 1px solid $badge_color_003;
              background: $badge_bg_003;
            }

            &.badge_color_004 {
              color: $badge_color_004;
              border: 1px solid $badge_color_004;
              background: $badge_bg_004;
            }
          }
        }

        .program_list_header {
          .program_detail_wrapper {
            display: flex;
            width: 100%;
            padding: 3px 0px;
            margin: auto 0;

            .program_text {
              font-size: 14px;
              color: $color_004;
              font-weight: bold;
              margin: auto 0;
            }

            .program_icon {
              background-color: $color_002;
              width: 40px;
              height: 40px;
              border-radius: 20px;
              margin-right: 18px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                width: 100%;
                max-width: 20px;
              }
            }
          }

          &.program_main_header {
            margin-top: 15px;
            border-radius: 8px;

            .mat-mdc-icon-button {
              margin: auto;
              margin-right: 0;
              height: auto;
            }

            .program_detail_wrapper {
              padding: 12px 20px;

              .program_text {
                font-size: 16px;
                color: $color_011;
              }
            }
          }
        }

        .tree_main_header_icon {
          width: 22px;
          height: 22px;
          // background-image: url(./assets/images/plus_circle.svg);
          background-image: url("../images/plus_circle.svg");
          background-size: cover;
          background-position: center;
          display: block;
          margin: auto;

          &.header_icon_active {
            // background-image: url(./assets/images/negative_circle.svg);
            background-image: url("../images/negative_circle.svg");
          }
        }
      }
    }
  }

  .mat-mdc-radio-button {
    // width: 110px;
    width: auto;
    margin-right: 42px;

    // background: purple;
    // margin: 25px 24px 0 24px !important;
    @media only screen and (max-width: 767px) {
      margin-right: 54px;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mdc-label {
      margin: 0;
    }
  }

  .mdc-form-field {
    font-size: 14px;
    color: $color_004;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-outer-circle {
    background: rgba(210, 210, 210, 0.15);
    border: 2px solid #d2d2d2;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-mdc-radio-button.mdc-accent.mat-mdc-radio-checked {

    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-inner-circle {
      background: #fff;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-outer-circle {
      background: $color_002;
      border-color: $color_002;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-persistent-ripple,
    .mat-ripple-element {
      background-color: $color_002;
    }
  }

  .footer_wrapper {
    padding: 20px;
    box-sizing: border-box;
    border-top: 1px solid $sectionSeparateColor;

    .text_13 {
      color: $color_003;
      font-weight: 500;
      text-align: center;
    }
  }

  .fld_phone_code {
    display: flex;

    .select_sp.cust_fld {
      width: 100px;

      .mat-mdc-form-field-infix {
        padding: 19px 20px !important;
        padding-right: 10px !important;
      }

      .mat-mdc-form-field-flex {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px solid transparent;
      }

      &.mat-form-field-should-float .mat-mdc-select-arrow-wrapper {
        transform: translateY(3px);
      }
    }

    .phon_sp.cust_fld {
      width: calc(100% - 100px);

      .mat-mdc-form-field-flex {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 1px solid transparent;
      }
    }
  }

  .cust_fld .mat-form-field-appearance-legacy .mat-form-field-label {
    top: 14px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    top: 14px !important;
  }

  // .mat_md_row_mr_65 .mat-radio-button {
  //   margin-right: 42px;
  // }
}

.program_panel_box {
  &.mat-expansion-panel {
    background-color: transparent;
    padding: 10px 0;
    border-top: 1px solid #ecedf0;
    border-radius: 0 !important;
    margin: 0 !important;
    box-shadow: none !important;
  }

  .mat-expansion-panel-header {
    height: auto !important;
    padding: 22px 24px;
    transition: 0.3s;

    .mat-expansion-indicator {
      width: 18px;
      max-width: 18px;
      max-height: 18px;
      height: 18px;
      display: block;
      margin-top: auto;
      border: 1px solid #212121;
      border-radius: 50%;
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.05552 1V7.1053' stroke='%23212121' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.11111 4.0527H1' stroke='%23212121' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      transform: none !important;

      &:after {
        display: none;
        font-size: 0;
      }
    }

    &.mat-expanded {
      .mat-expansion-indicator {
        background-image: url("data:image/svg+xml,%3Csvg width='8' height='2' viewBox='0 0 8 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.11111 1.0527H1' stroke='%23212121' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        transform: rotate(0) !important;
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0;
  }

  .mat-mdc-radio-button {
    width: auto;
  }
  .mdc-label {
    margin: 0;
  }

  .mdc-form-field {
    white-space: normal;
    // padding-left: 12px;
  }

  .program_panel_item {
    padding: 12px 24px;
    transition-duration: 0.3s;

    .mat-mdc-radio-button .mdc-radio {
      padding: 0 6px 0px 0px;
    }
  }
}

.program_head_title {
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #212121;
  margin: auto 0;
}

.program_child_title {
  font-size: 14px;
  color: $color_004;
  font-weight: bold;
  margin: auto 0;
}

// .program_icon {
//   background-color: $color_002;
//   width: 40px;
//   height: 40px;
//   border-radius: 20px;
//   margin-right: 18px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   img {
//     width: 100%;
//     max-width: 20px;
//   }
// }
.text_16 {
  font-size: 16px;
  line-height: 26px;
  margin: 0;
}

.cdk-overlay-container {
  .text_18 {
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    font-weight: 500;
    margin-bottom: 25px;
  }

  .text_14 {
    font-size: 14px;
    line-height: 26px;
  }

  .list_stp_001 {
    padding: 0 0 0 15px;

    li {
      padding-left: 10px;
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 25px;
    }
  }

  .list_stp_002 {
    padding: 0 0 0 30px;
    list-style-type: lower-alpha;

    li {
      padding-left: 10px;
      margin-bottom: 15px;
    }
  }
}

.link_color {
  color: $color_002;
  text-decoration: underline;
  text-decoration-color: $color_016;
  cursor: pointer;
}

.mat-mdc-select-panel {
  .mat-primary {
    position: relative;
  }

  .search-input {
    padding: 15px;
  }

  .input-search {
    padding: 6px;
    padding-left: 26px;
    position: relative;
    width: calc(100% - 20px);
    border: 1px solid;
    margin: 10px;
    background: #fcfcfc;
    border: 1px solid #ededed;
    box-sizing: border-box;
    border-radius: 6px;

    .mat-select-search-clear {
      position: absolute;
      right: 0;
      top: 0;
    }

    &.search-icon {
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8067 10.86L9.54 8.6C10.2713 7.66831 10.6681 6.51777 10.6667 5.33334C10.6667 4.2785 10.3539 3.24736 9.76784 2.37029C9.18181 1.49323 8.34885 0.809646 7.37431 0.405978C6.39978 0.00231083 5.32742 -0.103307 4.29285 0.102481C3.25829 0.308269 2.30798 0.81622 1.5621 1.5621C0.81622 2.30798 0.308269 3.25829 0.102481 4.29285C-0.103307 5.32742 0.00231083 6.39978 0.405978 7.37431C0.809646 8.34885 1.49323 9.18181 2.37029 9.76784C3.24736 10.3539 4.2785 10.6667 5.33334 10.6667C6.51777 10.6681 7.66831 10.2713 8.6 9.54L10.86 11.8067C10.922 11.8692 10.9957 11.9188 11.077 11.9526C11.1582 11.9864 11.2453 12.0039 11.3333 12.0039C11.4213 12.0039 11.5085 11.9864 11.5897 11.9526C11.671 11.9188 11.7447 11.8692 11.8067 11.8067C11.8692 11.7447 11.9188 11.671 11.9526 11.5897C11.9864 11.5085 12.0039 11.4213 12.0039 11.3333C12.0039 11.2453 11.9864 11.1582 11.9526 11.077C11.9188 10.9957 11.8692 10.922 11.8067 10.86ZM1.33334 5.33334C1.33334 4.54221 1.56793 3.76885 2.00746 3.11106C2.44698 2.45326 3.0717 1.94057 3.8026 1.63782C4.53351 1.33507 5.33777 1.25585 6.1137 1.41019C6.88962 1.56454 7.60235 1.9455 8.16176 2.50491C8.72117 3.06432 9.10214 3.77705 9.25648 4.55297C9.41082 5.3289 9.3316 6.13317 9.02885 6.86407C8.7261 7.59497 8.21341 8.21969 7.55562 8.65921C6.89782 9.09874 6.12446 9.33334 5.33334 9.33334C4.27247 9.33334 3.25505 8.91191 2.50491 8.16176C1.75476 7.41162 1.33334 6.3942 1.33334 5.33334Z' fill='%237E8599'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: 6px center;
    }
  }

  .no-search-data-wrapper {
    display: none;
    height: auto !important;
    pointer-events: none !important;

    &.mat-active {
      background: transparent !important;
    }

    .mat-ripple {
      display: none !important;
    }

    .search-reult {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #3699ff;
      text-align: center;
      cursor: pointer;
      pointer-events: all !important;
    }
  }
}

.select_input {
  padding: 12px 15px !important;
  position: sticky;
  top: 0;
  background: #fff !important;
  z-index: 9999;
  border-bottom: 1px solid #c7c7c7 !important;
}

.select_country_icon {
  max-width: 23px;
  margin-right: 8px;
}

.mat-mdc-snack-bar-container {
  background: #f44336 !important;

  p.text_13 {
    margin: 0;
    color: #fff;
  }
}

// .mat-primary .mat-pseudo-checkbox-checked,
// .mat-primary .mat-pseudo-checkbox-indeterminate {
//   background: $color_002 !important;
// }

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: $color_002 !important;
}

.example-additional-selection {
  font-size: 12px;
  color: #999a9e;
}

.notification_wrapper {
  width: 100%;
  background-color: $color_015;
  // background-image: url(./assets/images/information.svg);
  background-image: url("../images/information.svg");
  background-position: 20px 25px;
  background-size: 30px;
  background-repeat: no-repeat;
  padding: 20px 20px 20px 70px;
  overflow: hidden;
  border-radius: 8px;

  a {
    font-weight: 500;
  }

  .bld {
    font-weight: 500;
  }

  &.sm_notification {
    background-position: 20px center;
  }

  &.notif_spacer {
    margin-bottom: 30px;
  }

  &.notif_agreement_spacer {
    margin-top: 14px;
    margin-bottom: 20px;
  }

  .text_13 {
    margin-bottom: 20px;
    font-weight: 600;

    &.ads {
      margin-bottom: 2px;
    }
  }
}

.degree_type_sd {
  padding-top: 24px;
  background: #fff;

  // overflow: hidden;
  .sm_detail_header {
    margin-top: 0 !important;

    &.mt_10imp {
      margin-top: 10px !important;
    }

    &.mt_5imp {
      margin-top: 5px !important;
    }

    &.mt_12imp {
      margin-top: 12px !important;
      margin-left: 12px !important;
    }
  }

  &.degree_level_sd {
    padding-top: 0;

    + .degree_level_sd {
      margin-left: 6px;
      padding-left: 12px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 7px;
        top: 19px;
        width: 7px;
        height: 8px;
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='8' viewBox='0 0 7 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.81534 3.57955L0.411932 0.318182V1.59091L5.32386 3.95739L5.28409 3.87784V4.0767L5.32386 3.99716L0.411932 6.36364V7.63636L6.81534 4.375V3.57955Z' fill='%23212121'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;

        @media only screen and (max-width: 767px) {
          top: 37%;
        }
      }
    }

    .text_14 {
      max-width: none !important;
      margin: 0 !important;

      + .text_14 {
        margin-left: 6px !important;
    }
    }

    #arrow {
      margin-left: 5px;
      margin-right: 5px;
      }

    #arrow {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

.personal_details {
  position: relative;
  z-index: 999999;
  padding-top: 0px;
  background: #fff;
}

.afetr_arrow {
  padding-right: 18px;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: right 6px;
  margin-right: 8px;
  // background-image: url(./assets/images/arrow-point-to-right.svg);
  background-image: url("../images//arrow-point-to-right.svg");
}

.after_arrow {
  padding-right: 12px;
  background-repeat: no-repeat;
  background-position: right 7px;
  margin-right: 6px;
  // background-image: url(./assets/images/arrow-point-to-right.svg);
  background-image: url("data:image/svg+xml,%3Csvg width='7' height='8' viewBox='0 0 7 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.81534 3.57955L0.411932 0.318182V1.59091L5.32386 3.95739L5.28409 3.87784V4.0767L5.32386 3.99716L0.411932 6.36364V7.63636L6.81534 4.375V3.57955Z' fill='%23212121'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
}

.academicLevelBtnActive {
  transform: translateY(-70px);
}

.prgram_master_level {
  margin-top: 35px;
}

.ng-animating {
  overflow: hidden !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-search-panel {
  padding-top: 50px;

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-search-inner {
    position: sticky;
    top: 0;
    width: calc(100%);
    border-bottom: 1px solid #cccccc;
    background: white;
    margin-top: -50px;
    z-index: 99999;
  }

  .mat-select-search-input {
    padding: 15px;
    width: calc(100% - 40px);
  }

  .mat-select-search-clear {
    height: 50px;
  }
}

.country_fld {
  .country_img {
    display: inline-block;
  }

  .mat-mdc-select {
    display: inline-block;
  }

  .mat-mdc-form-field-infix {
    display: flex;
    padding-bottom: 20px;
  }
}

.remove_univer {
  transform: translate(0px, -13px);
  color: #dc3545 !important;
}

.sm_detail_gry_wrp {
  padding-top: 10px;

  .bg_wrp {
    width: 50%;
    background: $color_006;
    border-radius: 8px;
    padding: 15px;
  }
}

.form_submited_wrp {
  border-radius: 8px;
  padding: 30px;
  box-sizing: border-box;

  .submited_head {
    display: flex;
    align-items: center;

    .text_26 {
      font-weight: 600;
      font-size: 30px;
      line-height: 30px;
      color: #212121;
      margin-left: 16px;
    }

    img {
      max-width: 40px;
    }
  }

  .submited_step {
    margin-top: 50px;

    .text_26 {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #212121;
    }

    .list_sty_001 {
      margin-top: 23px;
      margin-bottom: 48px;
      padding-left: 20px;
      list-style: decimal;
      font-weight: 500;
      font-size: 15px;
      line-height: 23px;
      color: #212121;
      max-width: 526px;

      &:last-child {
        margin-bottom: 0;
      }

      li {
        margin-bottom: 24px;
      }

      .contact-details {
        margin-bottom: 16px;
      }
    }
  }
}

.main_container {
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  max-height: auto;

  .app-side-bar {
    position: sticky;
    top: 0;
    min-width: 390px;
    max-width: 390px;
    min-height: 100vh;
    max-height: 100vh;

    @media only screen and (max-width: 1125px) {
      // position: sticky;
      // top: 0;
      min-width: 100%;
      max-width: 100%;
      min-height: auto;
      max-height: initial;
      background-color: $mainBackground;
      z-index: 100;
      // opacity: 1;
    }

    @media only screen and (max-width: 991px) {
      top: -100px;
    }
  }

  .main-content {
    min-width: 0;
    flex: 1 1 0;

    @media only screen and (max-width: 1125px) {
      min-width: 100%;
    }
  }

  .content-top-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 32px;

    @media only screen and (max-width: 1125px) {
      display: none;
    }

    .help-text {
      font-weight: 400;
      // @media only screen and (max-width: 1125px){
      //   height: 16px;
      //   width: 150px;
      // }
      font-size: 13px;
      line-height: 16px;
      color: #7e8599;
      margin: 0;
    }

    .help-link {
      display: block;
      font-weight: 600;
      // @media only screen and (max-width: 1125px){
      //   height: 16px;
      //   width: 100px;
      // }
      font-size: 13px;
      line-height: 16px;
      color: #0082c8;
      margin-left: 8px;
    }
  }

  .mat-mdc-form-field-error {
    margin-top: 4px;
  }

  .info-row {
    border-top: 1px solid #f5f5f5;
    margin-top: 40px;
    padding-top: 40px;

    @media only screen and (max-width: 767px) {
      margin-top: 24px;
      padding-top: 0;
    }
  }
}

// progress circle color
.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: #ffffff;
}

.progress-icon {
  .mat-mdc-progress-spinner {
    svg {
      transform: rotate(-90deg) translate(5px, -5px);
      stroke-linecap: round;
    }
  }
}

.personal_deatain_container {
  .text_14 {
    max-width: none !important;
    margin: 12px 0 0 0;
  }
}

.main_container .btn_001[disabled] {
  opacity: 0.6;
  pointer-events: none;
}

.no-search-data {
  padding: 15px;
  padding-top: 0px;
  background: transparent;
  cursor: auto;

  .add-search-btn {
    border: none;
    border-radius: 6px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    padding: 10px 16px;
    outline: none;
    margin-left: 5px;
    margin-top: 5px;
    background: #0082c8;
    cursor: pointer;
    // @media only screen and (max-width: 750px) {
    //   padding: 5px 6px;
    //   font-size: 12px;
    // }
  }

  .text_14 {
    margin-bottom: 4px;

    &.bg-yellow {
      background: #ffffe0;
      padding: 0 10px;
      border-radius: 2px;
    }
  }
}

.mat-mdc-radio-button .mdc-radio {
  padding: 0 6px 0px 0px;
}

.mt_4imp {
  margin-top: 4px !important;
}

.mat-mdc-option {
  font-family: "Inter", sans-serif;
}
